<template>
  <div class="reportBox">
    <div v-if="reportList.length !== 0">
      <div class="reportItem" v-for="(item, index) in reportList" :key="index" @click="goto(item.reportCode, index)">
        <img v-if="item.reportCode === 0" :src="ossUrl + 'bgc1.png'" alt="" class="reportBgc" />
        <img v-else :src="ossUrl + 'bgc2.png'" alt="" class="reportBgc" />
        <div class="reportTitle singleLineOverflowHidden">
          {{
            item.reportCode === 0
            ? "免疫功能综合评估结果"
            : "新冠相关免疫力检测结果"
          }}
        </div>
        <div class="reportTime">
          <span>采样时间</span>
          <span style="margin-left: 24px">{{ item.collectdate }}</span>
        </div>
        <div class="reportTime">
          <span>报告时间</span>
          <span style="margin-left: 24px">{{ item.reportdate }}</span>
        </div>
      </div>
    </div>
    <div v-else class="emptyPic">
      <img src="../../images/order/empty.png" alt="" style="width: 200px; display: inline-block" />
      <div style="color: #999999; line-height: 22px">
        未查询到该用户下有检测结果
      </div>
    </div>
  </div>
</template>

<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
  data() {
    return {
      ossUrl: ossUrl,
      reportList: [],
    };
  },
  created() { },
  mounted() {
    let reportList = JSON.parse(localStorage.getItem("reportList"));
    if (reportList !== null && reportList !== undefined) {
      this.reportList = reportList;
    }
  },
  computed: {},
  methods: {
    goto(reportCode, index) {
      localStorage.setItem("report", JSON.stringify(this.reportList[index]));
      localStorage.removeItem("st");
      if (reportCode === 0) {
        //免疫评估结果
        this.$router.push("/report/immu");
      } else {
        // 新冠监测结果
        this.$router.push("/report/sars");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import url(../../static/css/global.css);

.reportBox {
  padding: 10px 16px;
}

.reportItem {
  position: relative;
  height: 113px;
  border-radius: 25px;
  padding: 16px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;

  .reportTitle {
    position: relative;
    font-size: 18px;
    color: #ffffff;
    line-height: 25px;
    letter-spacing: 2px;
    z-index: 999;
  }

  .reportTime {
    position: relative;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    letter-spacing: 1px;
    margin-top: 8px;
    z-index: 999;
  }

  .reportBgc {
    width: 100%;
    height: 113px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.emptyPic {
  margin-top: 100px;
  text-align: center;
}
</style>
